import React, {useState} from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
// import Image from "gatsby-image";
// import Carousel from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";

// import Layout from "../components/layout";
// import SEO from "../components/seo";
// import Scroller from "../components/scroller";
import styled from "styled-components";
import Layout from "../components/layout"

const getImages = graphql`
  query {
    videoImage: file(relativePath: { eq: "TT_HOMEPAGE_VIDEOIMAGE.png" }) {
      childImageSharp {
        fluid(maxWidth: 588) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    testimonialOne: file(
      relativePath: { eq: "TT_HOMEPAGE_TESTIMONIAL_IMAGE.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    testimonialTwo: file(relativePath: { eq: "testimonial-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    babyPhoto: file(relativePath: { eq: "TT_HOMEPAGE_WEREINTHIS_IMAGE.png" }) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    circleIconFluid: file(relativePath: { eq: "TT_HOMEPAGE_ICON_01.png" }) {
      childImageSharp {
        fluid(maxWidth: 63) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    personIconFluid: file(relativePath: { eq: "person-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 63) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    houseIconFluid: file(relativePath: { eq: "TT_HOMEPAGE_ICON_03.png" }) {
      childImageSharp {
        fluid(maxWidth: 63) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const HR = styled.hr`
  margin-bottom: 0px !important;
`;

const WidgetSection = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 200px;
`;

const Title = styled.div`
    font-family: "Schoolbell", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 3.4px;
    color: #ff4880;
    text-transform: uppercase;
`;

const SubTitle = styled.div`
    font-family: 'Fredoka One', sans-serif;
    font-weight: 400;
    font-size: 40px;
    color: #271344;
`;

const EnrollmentSectionText = styled.div`
    max-width: 500px;
    margin: auto;
`;

const Button = styled.a`
 margin: 30px;
 font-size: 18px;
 height: 65px;
 width: 250px;
 border-radius: 10px;
 border: none;
 box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
 background: rgb(141,217,252);
  cursor: pointer;
`;


// const VideoCoverImage = styled.img`
//   width: 588px;
//   height: 400px;
//   background-color: /fbc736;
//   padding-top: 15px;
//   margin: auto;
// `;

const IndexPage = () => {
  const data = useStaticQuery(getImages);
  const [accordionObject, setAccordionObject] = useState({
    name: "expression",
    value: true
  })
  
  return (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <div
                            className="elementor-section elementor-top-section elementor-element elementor-element-06481c4 elementor-section-stretched elementor-section-full_width elementor-section-height-min-height elementor-section-items-top elementor-section-height-default"
                            data-id="06481c4"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                            style={{background: "none"}}
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c1fe0d6"
                                data-id="c1fe0d6"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-811492b elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="811492b"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-21ccc75"
                                        data-id="21ccc75"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-04219a5 elementor-widget elementor-widget-cms_image_single"
                                            data-id="04219a5"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top wow pulse">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="104"
                                                    height="116"
                                                    src="../images/image-birth.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-099e703"
                                        data-id="099e703"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-33124d9 elementor-widget elementor-widget-cms_image_single"
                                            data-id="33124d9"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right wow fadeInRight">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="648"
                                                    height="274"
                                                    src="../images/image-clound.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <WidgetSection> 
                                    <Title>Enrolment</Title>
                                    <SubTitle>Choose your plan</SubTitle>
                                    <EnrollmentSectionText>Select the plan that works best for you and your child. Our Premium and Ultimate plan will save you money.</EnrollmentSectionText>
                                    <div className="cms-image-single cms-animation-left-to-right wow flash">
                                        <div className="cms-img-box">
                                          <StaticImage
                                            placeholder="none"
                                            width="99"
                                            height="23"
                                            src="../images/image-bee.png"
                                            className="attachment-full size-full"
                                            alt="Kindori"
                                            loading="lazy"
                                          />{" "}
                                        </div>
                                      </div>{" "}

                                      <div className="elementor-widget-wrap elementor-element-populated">
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-73812581 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="73812581"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-extended ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-102b5c40"
                                        data-id="102b5c40"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-33d5373e elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="33d5373e"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 wow flipInX" style={{backgroundColor: '#eee'}}>
                                                <div className="inner-content" style={{backgroundColor: '#1ab9ff'}}>
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                    <StaticImage
                                                      placeholder="none"
                                                        width="800"
                                                        height="800"
                                                        src="../images/image-fcb-1.jpg"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title" style={{color: '#271344', fontFamily: 'Fredoka One', fontWeight: 400}}>
                                                    Starter Plan{" "}
                                                  </h3>
                                                  <div className="item--description" style={{color: '#271344', fontSize: '16px', fontWeight: 'bold'}}>
                                                  $59.99 for a 1 month subscription{" "}
                                                  </div>
                                                  <div>
                                                    <a className="btn btn-primary hv-secondary" style={{marginBottom: '20px'}}>
                                                      <span className="btn-text">
                                                      Buy Starter Plan{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2b9e5136"
                                        data-id="2b9e5136"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-6fb89582 elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="6fb89582"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 ">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="800"
                                                        height="800"
                                                        src="../images/image-fcb-2.jpg"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title" style={{color: '#271344', fontFamily: 'Fredoka One', fontWeight: 400}}>
                                                    Premium Plan{" "}
                                                  </h3>
                                                  <div className="item--description" style={{color: '#271344', fontSize: '16px', fontWeight: 'bold'}}>
                                                  $161.97 for a 3 month subscription{" "}
                                                  </div>
                                                  <div>
                                                  <a className="btn btn-primary hv-secondary" style={{marginBottom: '20px'}}>
                                                      <span className="btn-text">
                                                      Buy Premium Plan{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-22c02e62"
                                        data-id="22c02e62"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-3ddb7e4a elementor-widget elementor-widget-cms_fancy_box"
                                            data-id="3ddb7e4a"
                                            data-element_type="widget"
                                            data-widget_type="cms_fancy_box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-fancy-box layout1 wow bounce">
                                                <div className="inner-content">
                                                  <div className="item--icon icon-image">
                                                    <div className="inner-icon">
                                                      <StaticImage
                                                        placeholder="none"
                                                        width="800"
                                                        height="800"
                                                        src="../images/image-fcb-3.jpg"
                                                        className="attachment-full"
                                                        alt=""
                                                        loading="lazy"
                                                      />{" "}
                                                    </div>
                                                  </div>

                                                  <h3 className="item--title" style={{color: '#271344', fontFamily: 'Fredoka One', fontWeight: 400}}>
                                                    Ultimate Plan{" "}
                                                  </h3>
                                                  <div className="item--description" style={{color: '#271344', fontSize: '16px', fontWeight: 'bold'}}>
                                                    $323.95 for a 6 month subscription{" "}
                                                  </div>
                                                  <div>
                                                  <a className="btn btn-primary hv-secondary" style={{marginBottom: '20px'}}>
                                                      <span className="btn-text">
                                                      Buy Ultimate Plan{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                  <div
                                    className="elementor-element elementor-element-2ded355 elementor-widget elementor-widget-cms_cta"
                                    data-id="2ded355"
                                    data-element_type="widget"
                                    data-widget_type="cms_cta.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="cms-cta layout1 ">
                                        <div className="inner-cms-cta">
                                          <div className="col-content"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              
                                  </WidgetSection>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-c67ed09 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="c67ed09"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">

<div></div>
                                      <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c7e8322"
                                        data-id="c7e8322"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
     </Layout>
  );
};

export default IndexPage;
